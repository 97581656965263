import React                   from 'react';
import styled                  from '@emotion/styled';
import { Container, Row, Col } from 'react-grid-system';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';
import { BsTools }   from 'react-icons/bs';


import HeaderImage   from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import SEO           from '@interness/web-core/src/components/modules/SEO/SEO';
import CallToAction      from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Subnavigation from '@interness/web-core/src/components/structure/Subnavigation/Subnavigation';

import Link from '@interness/web-core/src/components/elements/Link/Link';

import { menu } from '../../../data/service-menu';

const List = styled.div`
  h4 {
    margin-top: 0;
  }
`;

const J = styled.p`
  text-align: justify;
`;

const AtelierPage = (props) => {
  const data = props.data;
  return (
    <>
      <SEO title={'Schmiedekurse'}/>
      <Subnavigation menu={menu} menuTitle="Atelier Service"/>
      <HeaderImage>
        <Img fluid={props.data.headerImages.media[1].file.localFile.childImageSharp.fluid}/>
      </HeaderImage>
      <Spacer />
      <Wrapper>
        <Heading subtitle="Erschaffen Sie Einzigartiges" icon={<BsTools/>}>Goldschmiedekurs</Heading>
          <article>
            <Spacer height={30}/>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <Img fluid={data.goldschmiedekurs.childImageSharp.fluid}/>
                  <Spacer/>
                  <p>Impressionen vom Kursverlauf: <Link
                    to="/atelier-service/schmiedekurse/goldschmiedekurs-ablauf">Galerie</Link></p>
                  <p>Ergebnisse unserer Goldschmiedekursteilnehmer: <Link
                    to="/atelier-service/schmiedekurse/goldschmiedekurs-ergebnisse">Galerie</Link></p>
                  <p>Haben Sie interesse an unserem Goldschmiedekurs? <Link to="/contact">Kontaktieren Sie uns</Link>
                  </p>
                </Col>
                <Col md={6}>
                  <J>
                    Sie möchten ein Schmuckstück in Ihren eigenen Händen entstehen lassen? Entdecken Sie die
                    Faszination,
                    edles Metall von der Schmelze bis zum fertigen Stück zu Ihrem ganz persönlichen Schmuck zu formen.
                    Unser
                    Goldschmiede-Team begleitet Sie dabei. Zwei unvergessliche Tage mit bleibenden Werten.
                  </J>
                  <List>
                    <h4>Preise</h4>
                    <p>180 € pro Teilnehmer</p>

                    <h4>Kursdauer</h4>
                    <p>Freitags 14.30 Uhr – 19.00 Uhr<br/>
                      Samstags 09.00 Uhr – 14.00 Uhr<br/>
                      Die Teilnehmerzahl pro Kurs ist auf 6 Personen begrenzt.</p>

                    <h4>Termine</h4>
                    <p>Die Kurs-Termine für 2021 sind aktuell in Vorbereitung und werden zeitnah hier eingestellt.</p>

                    <h4>Steinbesatz</h4>
                    <p>Erforderliche Brillanten und Edelsteine sind in großer
                      Auswahl vorhanden.</p>

                    <h4>Gravuren</h4>
                    <p>Eine individuelle Maschinengravur ist im Teilnehmerpreis
                      enthalten. Hand- oder Lasergravuren können gegen Aufpreis
                      eingebracht werden.</p>

                    <h4>Materialkosten</h4>
                    <p>Für das von Ihnen benötigte Edelmetall und den evtl.
                      gewünschten Steinbesatz erstellen wir vorab unverbindlich
                      einen Kostenvoranschlag.</p>
                  </List>
                </Col>
              </Row>
            </Container>
          </article>
        <Spacer/>
      </Wrapper>
      <CallToAction/>
    </>
  )
};

export default AtelierPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "goldschmiede-headers"}) {
            ...MediaCollectionHeaderImages
        }
        goldschmiedekurs: file(relativePath: {eq: "goldschmiede/goldschmiedekurs.jpg"}) {
            name
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
